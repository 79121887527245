<template>
  <div>
    <transferenceManagebar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้าย ผลการพิจารณากลั่นกรอง"
      >
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
          class="mt-10"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />

        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="transference_manages"
          :search="search"
          item-key="manage_id_ref"
        >
          <template v-slot:item.manage_case_move="{ item }">
            <div>
              <v-chip v-if="item.manage_case_move === 'normal'" color="primary"
                >ปกติ</v-chip
              >
              <v-chip v-if="item.manage_case_move === 'quality'" color="info"
                >คุณภาพ</v-chip
              >
              <v-chip v-if="item.manage_case_move === 'special'" color="warning"
                >กรณีพิเศษ</v-chip
              >
            </div>
          </template>

          <template v-slot:item.college_name="{ item }">
            <v-chip color="">
              <span style="font-size:16px;"> {{ item.college_name }}</span>
            </v-chip>
          </template>

          <template v-slot:item.manage_time_s="{ item }">
            {{ item.manage_time_s + "/" + item.manage_year_s }}
          </template>

          <template v-slot:item.succ_college="{ item }">
            <v-chip :color="getColor(item.succ_college)" dark>
              <span style="font-size:16px;"> {{ item.succ_college }}</span>
            </v-chip>
          </template>

          <template v-slot:item.college_name_suss="{ item }">
            <v-chip v-if="item.college_name_suss === ''"> </v-chip>
            <v-chip
              v-else-if="item.college_name_suss !== ''"
              color="green"
              dark
            >
              <span style="font-size:16px;"> {{ item.college_name_suss }}</span>
            </v-chip>
          </template>

          <template v-slot:item.manage_comment_supervisor="{ item }">
            <v-chip
              class="ma-1"
              v-if="item.manage_comment_supervisor === 'approp'"
              color="green"
              @click.stop="
                manage_comment_supervisorPosition(item.manage_id_ref)
              "
              dark
              >เห็นควร</v-chip
            >
            <v-chip
              v-else-if="item.manage_comment_supervisor === 'inapprop'"
              color="warning"
              dark
              @click.stop="
                manage_comment_supervisorPosition(item.manage_id_ref)
              "
              >ไม่เห็นควร</v-chip
            >

            <v-chip
              v-else-if="item.manage_comment_supervisor === 'suspend'"
              color="red"
              dark
              @click.stop="
                manage_comment_supervisorPosition(item.manage_id_ref)
              "
              >ระงับย้าย</v-chip
            >
          </template>

          <template v-slot:item.manage_status_document="{ item }">
            <v-chip
              v-if="item.manage_status_document === 'complete'"
              color="green"
              @click.stop="comment_idPosition(item.manage_id_ref)"
              dark
              >ถูกต้องสมบูรณ์</v-chip
            >
            <v-chip
              v-else-if="item.manage_status_document === 'incomplete'"
              color="warning"
              dark
              @click.stop="comment_idPosition(item.manage_id_ref)"
              >ไม่สมบูรณ์</v-chip
            >
            <v-chip
              v-else-if="item.manage_status_document === 'do_not'"
              color="red"
              dark
              @click.stop="comment_idPosition(item.manage_id_ref)"
              >ไม่จัดส่ง</v-chip
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <v-chip v-if="item.status_select === 'agree'" color="warning" dark>
              <span style="font-size:16px;">ปกติ (2)</span>
            </v-chip>
            <v-chip
              v-else-if="item.status_select === 'sw_normal'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">สับเปลี่ยน (3)</span>
            </v-chip>
            <v-chip
              v-else-if="item.status_select === 'sw_agree'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">แลกเปลี่ยน (4)</span>
            </v-chip>
            <v-icon
              v-else-if="item.succ_college >= 1"
              color="red"
              large
              @click.stop="deletePosition(item.id_ref)"
              >mdi-delete-circle</v-icon
            >
            <v-icon
              v-else
              color="yellow"
              large
              @click.stop="select_idPosition(item.manage_id_ref)"
              >mdi-credit-card-plus</v-icon
            >
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model canceldialog -->
      <v-layout row justify-center>
        <v-dialog v-model="canceldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ยกเลิกรายการ"
              class="px-5 py-3 text_google"
            >
              <div class="text-right"></div>
            </base-material-card>
            <v-card-text>
              <v-form ref="cancelform" lazy-validation>
                {{ conditons_transfer_successs.id_cts }}
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <p>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.id_ref }}
                      </p>

                      <div class="text--center">
                        รหัสบัตรประชาชน :
                        {{ transference_manages_id_ref.id_card }}
                        ชื่อ-นามสกุล :
                        {{ transference_manages_id_ref.title_s
                        }}{{ transference_manages_id_ref.frist_name }}
                        {{ transference_manages_id_ref.last_name }}

                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </div>
                    </v-flex>
                    <v-flex md6>
                      <p>
                        รหัสวิทยาลัยปลายทาง :
                        {{ man_powerss.college_code || "สับเปลี่ยนตำแหน่ง" }}
                      </p>
                      <div>
                        วิทยาลัย :
                        {{ man_powerss.college_name || "สับเปลี่ยนตำแหน่ง" }}
                      </div>
                      เลขที่ตำแหน่ง :
                      {{ man_powerss.id_position || "สับเปลี่ยนตำแหน่ง" }}
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="canceldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn large color="warning" @click.stop="cancelSubmit()" rounded>
                <v-icon dark>mdi-pencil</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model positiondialog -->
      <v-layout row justify-center>
        <v-dialog v-model="positiondialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เลือกสถานศึกษา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatepositionform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.manage_id_ref }}
                      </h4>
                      รหัสบัตรประชาชน :
                      {{ transference_manages_id_ref.id_card }}
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ transference_manages_id_ref.title_s
                        }}{{ transference_manages_id_ref.frist_name }}
                        {{ transference_manages_id_ref.last_name }}

                        {{ transference_manages_id_ref.position_name }}

                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_code }}
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </h4>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_manages_id_ref.manage_time_s }} ปี :
                        {{ this.transference_manages_id_ref.manage_year_s }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="header_trans"
                        :items="transference_manage_locations"
                        class="elevation-1"
                      >
                      </v-data-table>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-card elevation="2" class="pa-2">
                        <h2>
                          เหตุผลในการย้าย :
                        </h2>
                        <h3>
                          {{ transference_manages_id_ref.manage_reason_detail }}
                        </h3>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-col cols="12" md="12">
                    <v-card elevation="2" class="pa-2">
                      <v-flex xs12>
                        <v-autocomplete
                          prepend-icon="mdi-account-details"
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_code"
                          v-model="updatepositions.ctscollege_code"
                          label="วิทยาลัย"
                          @change="man_powerQuery()"
                          required
                          :rules="[v => !!v || '']"
                          outlined
                        >
                        </v-autocomplete>
                      </v-flex>
                      <v-flex md12>
                        <v-autocomplete
                          prepend-icon="mdi-account-details"
                          :items="man_powers"
                          item-text="id_position"
                          item-value="id_position"
                          label="เลขที่ตำแหน่ง"
                          v-model="updatepositions.ctsid_position"
                          outlined
                          required
                          :rules="[v => !!v || '']"
                        >
                          <template v-slot:item="{ item }">
                            <div>
                              <div>
                                {{ item.id_position }}
                              </div>
                              <div>
                                {{ item.positions }}
                              </div>
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                    </v-card>
                  </v-col>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="positiondialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatepositionSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import transferenceManagebar from "../../components/admin/transferenceManagebar.vue";
export default {
  components: { transferenceManagebar },
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      position: "ครู",
      branch_s: [],
      manage_id_ref: [],
      valid: true,
      canceldialog: false,
      positiondialog: false,
      clear_dataDialog: false,
      commentDrdialog: false,
      comment_stb_Drdialog: false,
      times_select: "1",
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_time_s: [1, 2],
      manage_year_s: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_manages: [],
      edittransference_manage: {},
      search: "",
      pagination: {},

      singleSelect: false,
      selected: [],

      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "กรณี", align: "center", value: "manage_case_move" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "นามสกุล", align: "left", value: "last_name" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่งเดิม", align: "left", value: "id_position" },

        { text: "ผลการพิจารณา", align: "center", value: "actions" },
        { text: "แห่งใหม่", align: "center", value: "succ_college" },
        { text: "วิทยาลัยแห่งใหม่", align: "left", value: "college_name_suss" },
        { text: "เลขที่ตำแหน่งใหม่", align: "left", value: "id_position_new" }
      ],

      header_trans: [
        {
          text: "ลำดับ",
          align: "center",
          value: "manage_location_sequence_n"
        },
        {
          text: "รหัสอ้างอิง",
          align: "center",
          value: "manage_location_id_ref"
        },
        { text: "วิทยาลัย", align: "left", value: "college_name" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_managestatus: [],
      transference_manages_id_ref: [],
      conditons_transfer_successs: [],
      updatepositions_condition: {},
      man_power_cancel: {},
      addreturn_man_power: {},
      transference_manage_locations: [],
      conditons_transfer_success_del: [],
      man_powers: [],
      userstatus: {},
      updatepositions: {},
      man_powerss: [],
      id_return_man_powers: [],
      colleges: [],
      data_select: [],
      provinces: [],
      regions: [],
      periods: [],
      period_enable_process: "1",
      updatecomment: {}
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    let result_branch;
    result_branch = await this.$http.post("branch.php", {
      ApiKey: this.ApiKey
    });
    this.branch_s = result_branch.data;

    let result;
    result = await this.$http.post("collegetype.php", {
      ApiKey: this.ApiKey
    });
    this.collegetypes = result.data;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
    result = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result.data;

    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;

    /*     this.transference_manageQueryAll(); */
    await this.periodQuery();
    await this.transference_manageQueryAll();
  },
  methods: {
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_type: "manage"
      });
      this.periods = result_period.data;
    },

    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.times_select,
          manage_year_s: this.years_select
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;
    },

    async showSuccessPosition() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.times_select,
          manage_year_s: this.years_select,
          success_s: "ok"
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;
    },

    async select_idPosition(manage_id_ref) {
      this.transference_manage_locations = [];
      this.transference_manages_id_ref = [];
      
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });

      let result = await this.$http.post("transference_manage_location.php", {
        ApiKey: this.ApiKey,
        manage_location_id_ref: manage_id_ref
      });
  
      this.transference_manage_locations = result.data;
      

      this.transference_manages_id_ref = result_con.data;
      this.positiondialog = true;
    },

    async comment_idPosition(manage_id_ref) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });
      this.transference_manages_id_ref = result_con.data;
      this.commentDrdialog = true;
    },

    async manage_comment_supervisorPosition(manage_id_ref) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });
      this.transference_manages_id_ref = result_con.data;
      this.comment_stb_Drdialog = true;
    },

    async transference_manageQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.periods.period_times,
          manage_year_s: this.periods.period_year
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;
    },

    async man_powerQuery() {
      let man_power_result;
      man_power_result = await this.$http.post("jor18.php", {
        ApiKey: this.ApiKey,
        j18college_code: this.updatepositions.ctscollege_code
      });
      this.man_powers = man_power_result.data;
    },

    async updatecommentSubmit() {
      if (this.$refs.updatecommentform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.id_tfp = this.transference_manages_id_ref.id_tfp;
        this.updatecomment.comment_dr_c = this.transference_manages_id_ref.comment_dr_c;
        this.updatecomment.id_branch = this.transference_manages_id_ref.id_branch_tan;
        this.updatecomment.detail_comment = this.transference_manages_id_ref.detail_comment;
        this.updatecomment.manage_status_document = this.transference_manages_id_ref.manage_status_document;

        let result = await this.$http.post(
          "transference_manage.update.php",
          this.updatecomment
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manageQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.commentDrdialog = false;
      }
    },

    async updatecomment_stbSubmit() {
      if (this.$refs.updatecomment_stbform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.id_tfp = this.transference_manages_id_ref.id_tfp;
        this.updatecomment.manage_comment_supervisor = this.transference_manages_id_ref.manage_comment_supervisor;
        this.updatecomment.manage_comment_supervisor = this.transference_manages_id_ref.manage_comment_supervisor;

        let result = await this.$http.post(
          "transference_manage.update.php",
          this.updatecomment
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manageQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.comment_stb_Drdialog = false;
      }
    },

    /// updatepositionSubmit
    async updatepositionSubmit() {
      if (this.$refs.updatepositionform.validate()) {
        this.updatepositions.ApiKey = this.ApiKey;
        this.updatepositions.ctsid_ref = this.transference_manages_id_ref.manage_id_ref;
        this.updatepositions.ctstime_s = this.transference_manages_id_ref.manage_time_s;
        this.updatepositions.ctsyear_s = this.transference_manages_id_ref.manage_year_s;
        this.updatepositions.ctsid_postion_old = this.transference_manages_id_ref.id_position;
        this.updatepositions.ctscollege_code_old = this.transference_manages_id_ref.college_code;
        this.updatepositions.ctsid_branch = "-";

        this.updatepositions.ctsid_card = this.transference_manages_id_ref.id_card;

        this.updatepositions.ctsname_position = "ผู้บริหาร";
        this.updatepositions.ctsstatus_select = "demand";



        let result = await this.$http.post(
          "conditons_transfer_success.insert.php",
          this.updatepositions
        );

        if (result.data.status == true) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.transference_manageQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.positiondialog = false;
      }
    },

    async deletePosition(id_ref) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });
      this.transference_manages_id_ref = result_con.data;

      let result_man = await this.$http.post("man_power.php", {
        ApiKey: this.ApiKey,
        id_card: this.transference_manages_id_ref.id_card
      });
      this.man_powerss = result_man.data;

      let result_cts = await this.$http.post("conditons_transfer_success.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });

      this.conditons_transfer_successs = result_cts.data;
      this.canceldialog = true;
    },

    async cancelSubmit() {
      if (this.$refs.cancelform.validate()) {
        this.man_powerss.ApiKey = this.ApiKey;
        this.man_powerss.status_booking = "";

        this.conditons_transfer_successs.ApiKey = this.ApiKey;

        this.man_power_cancel.ApiKey = this.ApiKey;
        this.man_power_cancel.id_position = this.conditons_transfer_successs.id_postion_old;

        let result_man = await this.$http.post(
          "man_power.update_process.php",
          this.man_powerss
        );

        let result_man_delete = await this.$http.post(
          "man_power.delete.php",
          this.man_power_cancel
        );

        let result_cts = await this.$http.post(
          "conditons_transfer_success.delete.php",
          this.conditons_transfer_successs
        );

        if (
          result_man.data.status == true &&
          result_cts.data.status == true &&
          result_man_delete.data.status == true
        ) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "ยกเลิกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.transference_manageQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ยกเลิกข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.canceldialog = false;
      }
    },

    getColor(calories) {
      /*  if (calories > 400) return 'red'
        else if (calories > 200) return 'orange'
        else return 'green'  */

      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    period_years() {
      let yyyy = this.periods.period_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  }
};
</script>
